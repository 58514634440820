.page-content {
  .wp-block-image {
    .aligncenter img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .alignleft img {
      float: left;
    }

    .alignright img {
      float: right;
    }

    // standard width
    /* :not(.alignwide):not(.alignfull) img {
      // todo
    } */
    
    /* .alignwide img {
      // todo
    } */

    /* .alignfull img {
      // todo
    } */
  }

  .wp-block-cover {
    .wp-block-cover__inner-container {
      padding: 9vw 6vw;

      .has-large-font-size {
        font-size: 4em;
      }

      .has-text-align-left {
        text-align: left;
      }

      .has-text-align-right {
        text-align: right;
      }

      .has-text-align-center {
        text-align: center;
      }
    }

    &.has-background-dim {
      color: #FFF;
    }
  }

  .wp-block-gallery {
    .blocks-gallery-grid {
      display: flex;
      flex-flow: row wrap;

      .blocks-gallery-item {
        box-sizing: border-box;
        padding: 0.5em;
        float: left;
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
    // add styles for columns
    @for $i from 1 through 8 {
      &.columns-#{$i} .blocks-gallery-item {
        width: (100 / $i) + 0%;
      }
    }
  }

  ul:not(.blocks-gallery-grid) {
    list-style: disc;
    padding-left: 3vw;
  }

  .wp-block-text-columns,
  .wp-block-columns {
    display: flex;
    flex-flow: row wrap;

    .wp-block-column {
      box-sizing: border-box;
      min-width: 100%;

      @media screen and (min-width: 600px) {
        min-width: 30%;
      }

      @media screen and (min-width: 1024px) {
        min-width: auto;
      }
    }
  }

  /* .wp-block-button {
    // todo
  } */

  .wp-block-quote {
    cite {
      font-size: 1.3em;
      margin-top: 1.3em;
      opacity: 0.6;
    }

    &:not(.is-large) {
      border-left: 4px solid #000;
      margin: 0 0 28px;
      padding-left: 1em;
    }

    &.is-large {
      margin: 0 0 28px;
      padding-left: 1em;

      p {
        font-size: 2.4em;
      }
    }
  }

  .wp-block-code {
    font-family: Menlo,Consolas,monaco,monospace;
    font-size: 1.4em;
    padding: 0.8em 1em;
    border: 1px solid #e2e4e7;
    border-radius: 4px;
  }

  .wp-block-audio {
    audio {
      width: 100%;
    }
  }

  .wp-block-video {
    video {
      width: 100%;
    }
  }

  .wp-block-preformatted {
    font-family: Menlo,Consolas,monaco,monospace;
    font-size: 1.4em;
    white-space: pre-wrap;
  }
}
