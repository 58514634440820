input,
textarea,
.photon-input {
  font-family: $font-family-primary;
  opacity: 0.81;
  background-color: $purple;
  border: 0;
  padding: 18px 15px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    font-family: $font-family-primary;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $black;
  }
}

button,
.photon-button {
  width: 193px;
  opacity: 0.95;
  border-radius: 17.8px;
  box-shadow: 0 5px 14px 0 $shadow;
  background-color: $dark-purple;
  border: 1px solid $dark-purple;
  display: block;
  margin: 0 auto;
  padding: 12px 20px;
  color: $white;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.13;
  letter-spacing: 1.78px;
}
