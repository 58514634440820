h1 {
  font-size: 4em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.2em;

  @media screen and (min-width: 900px) {
    line-height: 1.5em;
  }
}

h2 {
  font-size: 3em;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.2em;

  @media screen and (min-width: 900px) {
    line-height: 1.5em;
  }
}

h3 {
  font-size: 2.5em;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.2em;

  @media screen and (min-width: 900px) {
    line-height: 1.5em;
  }
}

h4 {
  font-size: 2em;
  font-weight: 600;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.2em;

  @media screen and (min-width: 900px) {
    line-height: 1.5em;
  }
}

h5 {
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.2em;

  @media screen and (min-width: 900px) {
    line-height: 1.5em;
  }
}

h6 {
  font-size: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.2em;

  @media screen and (min-width: 900px) {
    line-height: 1.5em;
  }
}

p {
  font-size: 1.6em;
  font-weight: normal;
  line-height: 1.45em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.5em;
}

ul, ol {
  margin-top: 1em;
  margin-bottom: 1em;
}

li {
  font-size: 1.6em;
  font-weight: normal;
  line-height: 1.45em;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

// h1,
// h1.photon-h1,
// h2,
// h2.photon-h2 {
//   font-size: 44px;
//   font-weight: 300;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.45;
//   letter-spacing: normal;
//   color: $black;

//   @media screen and (min-width: 900px) {
//     font-size: 120px;
//     font-weight: 300;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.28;
//     letter-spacing: 3.33px;
//   }
// }

// p {
//   font-size: 14px;
//   font-weight: 300;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.43;
//   letter-spacing: normal;
//   color: $black;

//   @media screen and (min-width: 900px) {
//     font-size: 30px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     letter-spacing: normal;
//   }
// }
